// $my-bg: #282c34;

// @mixin my-text {
//   color: #61dafb;
// }

// USE
// @use "styles/utils";
// .my-class { background-color: utils.$my-bg; ... }
// .my-class { @include utils.my-text; ... }

.fit-cover {
  object-fit: cover;
}
