.CardInCard {
  display: inline-block;
  background: #6f678d;
  border-radius: var(--bs-border-radius-xl);
  width: 100%;
  .CardInCardTitle {
    text-align: center;
    color: white;
  }
  .CardInCardContent {
    background: white;
    border-radius: var(--bs-border-radius-xl);
    margin: 1.5rem;
  }
}
