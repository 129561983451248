.AppCarousel {
  div:nth-child(2),
  img {
    border-radius: var(--bs-border-radius-xl);
  }
}

.CarouselCaption {
  background-color: #00000099 !important;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 2.5rem;
  border-radius: var(--bs-border-radius-xl);
}
