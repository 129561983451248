.AppArticl {
  // background: #1c2c40;
  .AppArticlTitle {
    margin: 1.5rem;
  }
  .AppArticlContent {
    padding: 1.5rem;
    p {
      text-align: justify;
    }
  }
}
